import '../style/index.css';
// import '../style/index3.css';
import Header from './Header';
import Accueil from './Accueil';
import Restaurant from './Restaurant';
import Menu from './Menu';
// import MenuTest from './MenuTest';
import Cuisine from './Cuisine';
import Reservation from './Reservation';
import Contact from './Contact';
import Footer from './Footer';

function Body() {
  return (
      <main>
        <div className="headerAccueil">
          <div className="couleurOpacity">
            <Header />
            <Accueil />
          </div>
        </div>
        <Restaurant />
        <Menu />
        {/* <MenuTest /> */}
        <Cuisine />
        <Reservation />
        <Contact />
        <Footer />
      </main>
  );
}

export default Body;