import logo from '../images/logo.png';
import '../style/index.css';
import OuvertFerme from '../json/ouvert-ferme.json';

function Accueil() {
    const ouvertFermeWithDate = OuvertFerme.filter(item => (item.date !== '') || (item.texte !== ''));
    /*const restaurantGuruHtml = `
        <link href="https://awards.infcdn.net/2024/r_rcm.css" rel="stylesheet"/>
        <div id="b-rcircle" data-length="28" class=" b-rcircle_black f13 " onclick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.b-rcircle_r-link').href);return 0;}">
        <a href="https://restaurantguru.com/Le-louisabella-Biron-Aquitaine" class="b-rcircle_r-link" target="_blank">LE LOUISABELLA - RESTAURANT DU LAC DE BIRON ORTHEZ</a>
        <p class="b-rcircle_year">2024</p>
        <div class="b-rcircle_bottom ">
        <p class="b-rcircle_str1 ">Recommandé</p>
        </div>
        <div class="b-rcircle_heading">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="144px" height="144px" viewBox="0 0 144 144">
        <defs>
        <path id="b-rcircle-arc" d="M 12 72 a 60 60 0 0 0 120 0"></path>
        </defs>
        <text class="b-rcircle_heading__bottom " fill="#fff" text-anchor="middle">
        <textPath startOffset="50%" xlink:href="#b-rcircle-arc"><a href="https://restaurantguru.com/" target="_blank" class="b-rcircle_heading__link">Restaurant Guru</a></textPath>
        </text>
        </svg>
        </div></div>`;*/

    return (
        <section id="accueil">
            <div className="accueilTexte">
                {/* <div className="certification">
                    <div dangerouslySetInnerHTML={{ __html: restaurantGuruHtml }} className='back-certif' />
                </div> */}
                <div className='restoLogo'>
                    <img src={logo} alt="logo Louisabella" className='logoAccueil' />
                </div>
            </div>
            {ouvertFermeWithDate.length > 0 && (
                <div className="ouvferm">
                    {ouvertFermeWithDate.map((item, index) => (
                        <div key={index}>
                            <h2>{item.name}</h2>
                            <h3>{item.date}</h3>
                            <p>{item.texte}</p>
                            <p>{item.textebis}</p>
                            <p className='littleTexte'>{item.texteter}</p>
                        </div>
                    ))}
                </div>
            )}
        </section>
    )
}

export default Accueil;